import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">

<path d="M2300 3820 c-287 -51 -482 -143 -630 -295 l-55 -56 70 47 c160 107
446 201 738 242 l122 18 0 29 0 30 -70 2 c-38 1 -117 -7 -175 -17z"/>
<path d="M2555 3729 c-343 -35 -718 -148 -902 -272 -78 -52 -137 -120 -172
-197 -34 -74 -25 -80 34 -23 68 63 264 163 435 221 201 68 495 131 689 148 69
6 89 11 108 30 29 30 29 49 -2 79 -27 28 -46 29 -190 14z"/>
<path d="M2435 3510 c-432 -78 -800 -220 -950 -365 -66 -64 -97 -141 -93 -227
l3 -60 32 46 c111 157 547 337 1033 427 145 26 170 42 170 107 0 43 -28 77
-70 86 -14 2 -70 -3 -125 -14z"/>
<path d="M2195 3184 c-329 -90 -584 -202 -705 -312 -73 -66 -95 -116 -88 -205
5 -60 45 -174 47 -133 1 11 12 38 26 62 70 119 381 271 759 372 176 47 201 64
201 138 0 39 -5 50 -33 75 -41 36 -82 37 -207 3z"/>
<path d="M2055 2816 c-374 -126 -575 -263 -575 -392 0 -48 38 -129 80 -169
l30 -29 0 38 c0 103 246 255 582 360 63 20 123 45 132 55 41 45 36 124 -10
160 -40 31 -93 26 -239 -23z"/>
<path d="M2295 2565 c-307 -86 -553 -208 -624 -311 -74 -107 -30 -196 127
-255 22 -8 25 -8 15 2 -7 7 -13 29 -13 48 0 97 245 235 578 326 68 19 130 40
137 45 43 36 42 125 -2 160 -35 28 -76 25 -218 -15z"/>
<path d="M2580 2341 c-310 -66 -551 -163 -657 -266 -45 -43 -53 -57 -53 -87 0
-62 76 -116 199 -143 l33 -7 -21 21 c-12 12 -21 30 -21 41 0 81 248 203 540
265 95 20 119 29 138 50 38 45 26 102 -28 130 -34 18 -24 18 -130 -4z"/>
<path d="M2616 2100 c-263 -56 -466 -156 -466 -231 0 -40 41 -59 147 -66 l85
-6 -13 22 c-13 21 -11 25 20 51 41 35 169 83 266 99 108 19 113 20 129 37 22
22 20 70 -4 94 -24 24 -49 24 -164 0z"/>
<path d="M2616 1914 c-64 -16 -152 -59 -160 -80 -3 -7 2 -18 10 -25 32 -26
200 32 279 96 l30 24 -50 0 c-27 0 -76 -7 -109 -15z"/>
<path d="M1178 1691 c10 -6 13 -29 10 -85 l-3 -76 35 1 c19 0 29 3 23 6 -7 2
-13 17 -13 32 0 22 5 27 28 29 15 1 33 10 40 19 33 45 -5 83 -83 83 -31 0 -45
-3 -37 -9z m78 -9 c27 -18 19 -72 -11 -72 -11 0 -15 12 -15 40 0 40 5 46 26
32z"/>
<path d="M1338 1688 c7 -7 12 -39 12 -73 0 -34 -5 -66 -12 -73 -9 -9 4 -12 59
-12 62 0 71 2 76 20 3 11 1 20 -4 20 -5 0 -9 -4 -9 -10 0 -5 -16 -10 -35 -10
-32 0 -35 2 -35 31 0 27 3 30 28 27 19 -2 27 2 27 12 0 10 -8 14 -27 12 -25
-3 -28 0 -28 27 0 26 4 31 25 31 14 0 28 -7 31 -15 9 -22 23 -18 17 5 -5 17
-14 20 -71 20 -51 0 -63 -3 -54 -12z"/>
<path d="M1508 1688 c7 -7 12 -39 12 -73 0 -34 -5 -66 -12 -73 -9 -9 3 -12 54
-12 62 0 66 1 70 25 6 27 -6 34 -16 10 -3 -9 -18 -15 -36 -15 l-30 0 0 65 c0
50 4 66 18 74 13 8 8 10 -27 11 -35 0 -42 -3 -33 -12z"/>
<path d="M1683 1688 c-16 -8 -23 -20 -23 -39 0 -22 8 -31 38 -46 20 -10 37
-25 37 -33 0 -22 -46 -25 -62 -4 -12 17 -13 17 -13 -3 0 -46 101 -33 108 14 2
16 -7 28 -38 48 -23 14 -40 31 -37 38 6 20 40 20 54 1 12 -17 13 -17 13 3 0
32 -35 41 -77 21z"/>
<path d="M1858 1688 c16 -16 16 -130 0 -147 -11 -11 -2 -12 51 -9 54 4 68 9
88 32 40 47 27 106 -28 125 -45 16 -127 15 -111 -1z m110 -26 c15 -20 16 -84
0 -100 -7 -7 -25 -12 -40 -12 -28 0 -28 1 -28 64 0 65 8 79 40 68 8 -3 21 -12
28 -20z"/>
<path d="M2113 1688 c-6 -7 -24 -46 -41 -85 l-30 -73 25 0 c22 0 24 2 13 15
-18 22 0 37 36 33 23 -2 29 -8 28 -25 0 -20 5 -23 33 -23 32 0 33 1 19 23 -8
12 -24 47 -36 77 -25 65 -33 75 -47 58z m7 -47 c0 -5 3 -16 6 -25 4 -12 0 -16
-16 -16 -16 0 -20 4 -16 16 3 9 6 20 6 25 0 5 5 9 10 9 6 0 10 -4 10 -9z"/>
<path d="M2220 1691 c0 -5 -3 -16 -6 -25 -3 -9 -1 -16 5 -16 6 0 11 6 11 14 0
16 19 25 38 19 8 -3 12 -26 12 -68 0 -50 -4 -66 -17 -74 -14 -8 -9 -10 27 -11
34 0 41 3 32 12 -14 14 -16 134 -2 143 5 3 18 -3 30 -15 l20 -20 0 25 c0 24
-2 25 -75 25 -41 0 -75 -4 -75 -9z"/>
<path d="M2430 1633 c-17 -38 -35 -76 -40 -85 -9 -15 -6 -18 17 -18 20 0 24 3
16 11 -20 20 -3 39 34 39 32 0 35 -2 31 -25 -5 -23 -2 -25 28 -25 30 0 33 2
24 18 -5 9 -23 47 -40 85 -16 37 -32 67 -35 67 -3 0 -19 -30 -35 -67z m28 -31
c-27 -5 -32 2 -18 29 l13 22 13 -24 c11 -22 11 -24 -8 -27z"/>
<path d="M2650 1687 c-39 -20 -33 -57 12 -82 47 -25 51 -55 8 -55 -20 0 -30 5
-30 15 0 8 -4 15 -10 15 -5 0 -10 -9 -10 -19 0 -26 50 -37 87 -19 43 20 38 58
-9 81 -25 11 -38 24 -38 37 0 26 37 27 56 3 12 -17 13 -17 14 3 0 34 -36 43
-80 21z"/>
<path d="M2768 1688 c16 -16 16 -130 0 -147 -11 -10 1 -11 57 -9 66 3 70 4 73
28 2 22 1 23 -8 8 -7 -12 -23 -18 -45 -18 -32 0 -35 2 -35 30 0 25 4 30 24 30
13 0 27 -6 29 -12 3 -7 5 3 5 22 0 19 -2 29 -5 23 -2 -7 -16 -13 -29 -13 -22
0 -25 4 -22 28 4 32 37 42 62 17 15 -15 16 -15 16 4 0 19 -6 21 -67 21 -52 0
-64 -3 -55 -12z"/>
<path d="M2930 1685 c8 -10 11 -39 8 -85 l-4 -70 27 0 c26 0 27 2 23 43 -4 36
-3 40 8 23 30 -43 45 -55 73 -61 l30 -7 -21 19 c-29 24 -59 73 -46 73 6 0 17
10 23 22 18 33 -19 58 -83 58 -44 0 -49 -2 -38 -15z m74 -1 c19 -7 21 -35 4
-52 -20 -20 -28 -14 -28 23 0 38 0 38 24 29z"/>
<path d="M3126 1623 c20 -43 39 -81 43 -85 3 -4 25 31 47 77 39 84 39 85 16
85 -13 0 -20 -4 -17 -10 7 -11 -12 -79 -26 -94 -13 -15 -50 76 -37 91 8 10 3
13 -25 13 l-36 0 35 -77z"/>
<path d="M3278 1688 c16 -16 16 -130 0 -146 -9 -9 -3 -12 27 -12 30 0 36 3 27
12 -16 16 -16 130 0 146 9 9 3 12 -27 12 -30 0 -36 -3 -27 -12z"/>
<path d="M3420 1682 c-66 -32 -63 -108 4 -141 37 -17 90 -4 94 24 3 19 2 19
-11 3 -21 -27 -63 -23 -83 7 -19 30 -12 90 12 99 27 11 54 6 60 -9 4 -8 10
-15 15 -15 11 0 -1 33 -15 42 -17 12 -38 9 -76 -10z"/>
<path d="M3548 1688 c16 -16 16 -130 0 -147 -11 -10 1 -11 57 -9 66 3 70 4 73
28 2 22 1 23 -8 8 -7 -12 -23 -18 -45 -18 -32 0 -35 2 -35 30 0 25 4 30 24 30
13 0 27 -6 29 -12 3 -7 5 3 5 22 0 19 -2 29 -5 23 -2 -7 -16 -13 -29 -13 -22
0 -25 4 -22 28 4 32 37 42 62 17 15 -15 16 -15 16 4 0 19 -6 21 -67 21 -52 0
-64 -3 -55 -12z"/>
<path d="M3740 1687 c-39 -20 -33 -57 12 -82 47 -25 51 -55 8 -55 -20 0 -30 5
-30 15 0 8 -4 15 -10 15 -5 0 -10 -9 -10 -19 0 -37 90 -38 110 -1 14 27 2 49
-36 65 -23 9 -34 21 -34 34 0 26 37 28 56 4 12 -17 13 -17 14 3 0 34 -36 43
-80 21z"/>
<path d="M1481 1398 c0 -32 2 -58 7 -58 4 0 6 19 4 43 -5 70 -10 76 -11 15z"/>
<path d="M1614 1395 c1 -42 4 -50 21 -52 15 -1 15 0 3 3 -12 4 -18 19 -21 52
l-4 47 1 -50z"/>
<path d="M1781 1398 c0 -32 2 -58 7 -58 4 0 6 19 4 43 -5 70 -10 76 -11 15z"/>
<path d="M2587 1414 c-9 -9 9 -68 21 -70 7 -1 8 0 2 3 -14 7 -13 50 2 55 8 3
6 6 -4 10 -9 4 -18 5 -21 2z"/>
<path d="M2842 1383 c2 -20 9 -38 16 -39 7 -1 8 0 2 3 -14 7 -13 50 2 55 7 3
5 7 -6 11 -15 6 -17 2 -14 -30z"/>
<path d="M1432 1385 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1647 1398 c6 -7 13 -20 16 -28 4 -10 6 -8 6 6 1 12 -6 25 -15 28
-13 5 -14 3 -7 -6z"/>
<path d="M2358 1403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2456 1395 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M2538 1375 c2 -19 7 -35 11 -35 4 0 5 16 3 35 -2 19 -7 35 -11 35 -4
0 -5 -16 -3 -35z"/>
<path d="M2645 1393 c4 -10 10 -26 12 -35 3 -10 9 -15 14 -12 5 3 2 15 -6 28
-8 12 -12 26 -8 29 3 4 1 7 -6 7 -9 0 -11 -6 -6 -17z"/>
<path d="M2935 1375 c0 -19 3 -35 8 -35 4 0 7 16 7 35 0 19 -3 35 -7 35 -5 0
-8 -16 -8 -35z"/>
<path d="M2988 1403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3150 1357 c-4 -39 -1 -57 7 -57 6 0 9 8 6 19 -3 12 2 20 16 24 12 3
24 12 27 21 7 17 -12 46 -29 46 -6 0 -2 -6 8 -14 21 -15 15 -46 -9 -46 -9 0
-16 12 -18 33 -3 22 -5 15 -8 -26z"/>
<path d="M3339 1402 c7 -5 11 -14 9 -18 -3 -5 -2 -18 3 -29 7 -16 8 -12 6 14
-1 21 -8 36 -17 38 -12 4 -13 3 -1 -5z"/>
<path d="M1523 1384 c-3 -9 2 -23 13 -32 17 -16 17 -16 5 0 -7 10 -11 25 -7
33 3 8 3 15 0 15 -2 0 -7 -7 -11 -16z"/>
<path d="M1572 1375 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1933 1384 c-3 -9 2 -23 13 -32 17 -16 17 -16 5 0 -7 10 -11 25 -7
33 3 8 3 15 0 15 -2 0 -7 -7 -11 -16z"/>
<path d="M1982 1375 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2020 1389 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2110 1389 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2284 1370 c1 -16 7 -25 19 -26 11 -1 12 0 3 3 -8 2 -17 14 -19 26
-3 18 -4 18 -3 -3z"/>
<path d="M3040 1390 c0 -5 9 -14 20 -20 11 -6 20 -9 20 -8 0 2 -9 11 -20 21
-11 9 -20 13 -20 7z"/>
<path d="M1861 1374 c-1 -20 26 -41 37 -29 3 3 0 5 -7 5 -6 0 -16 10 -21 23
-9 22 -9 22 -9 1z"/>
<path d="M2068 1365 c-3 -14 -1 -25 3 -25 5 0 9 11 9 25 0 14 -2 25 -4 25 -2
0 -6 -11 -8 -25z"/>
<path d="M2158 1365 c-3 -14 -1 -25 3 -25 5 0 9 11 9 25 0 14 -2 25 -4 25 -2
0 -6 -11 -8 -25z"/>
<path d="M2201 1374 c-1 -20 26 -41 37 -29 3 3 0 5 -7 5 -6 0 -16 10 -21 23
-9 22 -9 22 -9 1z"/>
<path d="M3230 1377 c0 -15 20 -37 34 -37 5 0 2 6 -9 13 -10 8 -15 19 -11 25
4 7 2 12 -3 12 -6 0 -11 -6 -11 -13z"/>
<path d="M3401 1377 c2 -1 15 -11 29 -22 l25 -20 -19 23 c-11 12 -24 22 -29
22 -6 0 -8 -1 -6 -3z"/>
<path d="M1700 1361 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M2020 1355 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M2760 1361 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z"/>
<path d="M2110 1350 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2410 1350 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3317 1349 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M1390 1321 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
